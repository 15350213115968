/* ==============================================
   1. Common Styles (共通スタイル)
   ============================================== */
/* 変数定義 */
:root{
  /*--principal:#272b35;
  --secundario:#ffffff;
  --border:rgba(0, 0, 0, 0.3);
  --fondo:#16161d;
  --tercer:#F61067;
  --cuarto:#962557;
  */--principal:#ffffff;
  --secundario:#272b35;
  --border:#c1c6d3;
  --tercer:#b34c7a;
  --cuarto:#962557;
}

/* 基本設定 */
::selection {
  color: var(--principal);
  background: var(--cuarto);
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&family=Roboto+Mono&display=swap');
body{
  margin: 0;
  padding: 0;
  font-weight: normal;
  color: var(--secundario);
  overscroll-behavior-y: contain;
}
*:not(i){
  font-family: 'Montserrat', sans-serif;
}

/* 共通のヘッダー */
.header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  flex-direction: column;
}

.header-top {
  background-color: #C7C7C7;
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
}

.header-title {
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-align: left;
  margin: 0;
  letter-spacing: 2px;
  padding-left: 50px;
  line-height: 1;
}

/* ==============================================
   2. GradientGallery Styles
   ============================================== */
/* メインレイアウト */
.App{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 250px;
  left: 0;
  right: 0;
  min-height: calc(100vh - 250px);
}

/* 最終ステップのみスクロール制御を適用 */
.App.final-step {
  position: fixed;
  top: 250px;
  left: 0;
  right: 0;
  bottom: 0;
  height: calc(100vh - 250px);
  min-height: auto;
  background-color: inherit;
}

h1{
  font-size: 2rem;
  margin: 0;
  font-weight: bold;
  color: var(--tercer);
}
.colorTitle{
  margin-bottom: 0.5rem;
}
h2{
  font-size: 1.5rem;
  margin: 0;
  margin-top: 0.5rem;
  margin-bottom: 0.25rem;
  font-weight: normal;
  color:var(--border);
}
.downloadDialog{
  position: fixed;
  margin: auto;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 5;
  display: flex;
  justify-content: center;
  align-items: center;

}
.downloadContainer{
  background-color: var(--principal);
  border: 3px solid var(--border);
  border-radius: 8px;
  padding: 24px;
  padding-top: 16px;
  padding-bottom: 16px;
  width: 30rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
.downloadContainer .buttonX{
  position: absolute;
  right: 24px;
}
.downloadContainer .widerButton{
  margin-top: 16px;
}
.Contenedor{
  width: 100%;
  max-width: 1600px;
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 20px;
  
  align-items: center;
  height: 100%;
  max-height: 900px;
}
.contenedorCanvas{
  width: 45%;
  height: 100%;
}
.GradientCanvas{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.panel{
  font-size: 2rem;
  width: 45%;
  padding: 24px;
  padding-top: 16px;
  padding-bottom: 8px;
  border: 3px solid var(--border);
  border-radius: 8px;
  background-color: var(--principal);
  display: flex;
  flex-direction: column;
  height: 100%;
  user-select: none;
}
label{
  display: block;
}

.range{
  display: flex;  
  justify-content: space-around;
  align-items: center;
}

i{
  color:var(--tercer);
  pointer-events: none;
}

input[type="range"] {
  appearance: none;
  width: 80%;
  height: 3px;
  border-radius: 8px;
  background-color: var(--border);
  cursor: pointer;
}
input[type="range"]:focus{
  outline: none;
  background-color: var(--cuarto);
}
input[type="range"]:focus::-webkit-slider-thumb{
  border: 3px solid var(--cuarto);
  background-color: var(--principal);
}
input[type="range"]:focus::-moz-range-thumb{
  border: 3px solid var(--cuarto);
  background-color: var(--principal);
}
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 30px;
  border-radius: 8px;
  background: var(--principal);
  cursor: pointer;
  border: 3px solid var(--border);
}
input[type="range"]::-moz-range-thumb {
  width: 15px;
  height: 30px;
  border-radius: 8px;
  background: var(--principal);
  cursor: pointer;
  border: 3px solid var(--border);
}

.line{
  width: 100%;
  height: 3px;
  border-radius: 5px;
  background-color: var(--border);
  margin: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  
}

button{
  border: 3px solid var(--border);
  border-radius: 8px;
  font-size: 1.5rem;
  height: 2rem;
  width: 2rem;
  display: inline-flex;
  justify-content: center;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  padding: 0px;
  color: var(--tercer);
  line-height: 0;
}
button span{
  pointer-events: none;
}

button:focus{
  outline: none;
  border-color: var(--secundario);
  background-color: var(--cuarto);
  color: var(--principal);
}
button:focus i{
  color: var(--principal);
}
.colorsMiddle{
  flex-grow: 1;
  overflow: hidden;
  overflow-x: auto;
}
button:hover{
  border-color: var(--cuarto);
  /*color: var(--principal);*/
}
button:hover i{
  transition: transform 0.3s;
  transition-timing-function: cubic-bezier(0.5, -0.25, 0.5, 1.25);
}
button:hover .fa-arrows-rotate{
  transform: rotate(180deg);
}

.buttonX{
  border: none;
  background: none;
  width: 1rem;
}
.buttonX i{
  color: var(--border);
}
.buttonX:hover{
  background-color: transparent;
}
.buttonX:hover i{
  color: var(--tercer);
}
.widerButton{
  width: auto;
  height: auto;
  padding: 0.3rem 0.5rem;
}
.allColors{
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  height: 100%;
  color: var(--border);
}
.colorDiv{
  align-items: center;
  margin-left: 0rem;
  display: flex;
  width: 14rem;
  gap: 0.3rem;
  height: 2rem;
}
.colorInput,.inputSize{
  font-size: 1.8rem;
  border: none;
  border-bottom: 3px solid var(--border);
  font-family: 'Roboto Mono', monospace;
  padding-top: 4px;
  width: 6.5rem;
  color: var(--border);
  height: 2rem;
  padding-left: 0rem;
  cursor: pointer;
  text-align: center;
  letter-spacing: -1px;
}
.colorInput:focus,.inputSize:focus{
  outline: none;
  border-color: var(--tercer);
  color: var(--secundario);
}
.centerButtons{
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.picker {
  position: relative;
  display: inline-block;
}

.swatch {
  
  width: 2rem;
  height: 2rem;
  border-radius: 8px;
  border: 3px solid var(--border);
  cursor: pointer;
  display: block;
}
.swatch:focus{
  outline: none;
  border-color: var(--cuarto);
  border-width: 6px;
}

.popover {
  position: absolute;
  bottom: calc(100% + 200px);
  left: 0;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  z-index: 4;
}
.react-colorful{
  position: fixed !important;
}
@media only screen and (max-width: 768px) {
  html{
    font-size: 0.9rem;
  }
  .App{
    padding: 0;
    display: block;
  }
  .Contenedor{
    flex-direction: column;
    gap: 10px;
    height: 100vh;
    max-height: none;
    padding: 10px;
  }
  .contenedorCanvas{
    width: 100%;
    z-index: 3;
    height: auto;
  }
  .GradientCanvas{
    height: 100vh;
    max-height: 45vh;
  }
  .panel{
    width: 100%;
    height: 100%;
    overflow: scroll;
    padding: 10px;
    overflow-x: hidden;
  }
  .colorsMiddle{
    overflow: visible;
    padding-left: 1rem;
  }
  .widerButton{
    font-size: 1.5rem;
  }
  .widerButton span{
    display: none;
  }
  .react-colorful{
    bottom: 10px;
  }
}

.batch-download-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 12px 24px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.2);
  transition: all 0.3s ease;
}

.batch-download-button:hover {
  background-color: #45a049;
  box-shadow: 0 4px 8px rgba(0,0,0,0.3);
}

.batch-download-button:active {
  transform: translateY(2px);
}

.gallery-layout {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.gallery-container {
  width: 100%;
  max-width: calc(100% - 200px);
  margin: 80px auto;
  box-sizing: border-box;
}

.gradient-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 40px;
  width: 100%;
}

.gradient-item {
  position: relative;
  width: 100%;
  padding-top: 150%; /* 2:3のアスペクト比 */
  overflow: visible;
}

.gradient-item-background {
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background-color: white;
  border-radius: 12px;
  z-index: 0;
}

.gradient-image {
  position: absolute;
  object-fit: cover;
  border-radius: 0;
  box-shadow: none;
  z-index: 1;
}

.color-info {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 20px 25px 20px;
  z-index: 2;
}

.color-row {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
  justify-content: space-between;
}

.color-row:last-child {
  margin-bottom: 0;
}

.color-box {
  width: 60%;
  height: 8px;
  border-radius: 0;
}

.color-hex {
  font-family: 'Roboto Mono', monospace;
  font-size: 12px;
  color: white;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
  font-weight: 500;
  text-align: right;
  width: 35%;
}

.loading {
  text-align: center;
  padding: 20px;
  font-size: 1.2em;
  color: #666;
}

.create-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: bold;
  transition: background-color 0.3s;
}

.create-button:hover {
  background-color: #45a049;
}

/* レスポンシブ対応 */
@media only screen and (max-width: 1500px) {
  .gradient-grid {
    grid-template-columns: repeat(5, 1fr);
    gap: 35px;
  }
  .gallery-container {
    max-width: calc(100% - 100px);
    /* margin: 0 auto; */
  }
}

@media only screen and (max-width: 1200px) {
  .gradient-grid {
    grid-template-columns: repeat(4, 1fr);
    gap: 35px;
  }
  .gallery-container {
    max-width: calc(100% - 100px);
    /* margin: 0 auto; */
  }
}

@media only screen and (max-width: 900px) {
  .gradient-grid {
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }
  .gallery-container {
    max-width: calc(100% - 80px);
    margin: 0 auto;
  }
}

@media only screen and (max-width: 600px) {
  .gradient-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 25px;
  }
  .gallery-container {
    max-width: calc(100% - 60px);
    margin: 0 auto;
  }
}

.loading-placeholder {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: shimmer 1.5s linear infinite;
  border-radius: 0;
  z-index: 1;
}

@keyframes shimmer {
  0% {
    background-position: 200% 0;
  }
  100% {
    background-position: -200% 0;
  }
}

/* ヘッダー関連 */
.header-bottom {
  background-color: #E0E0E0;
  height: 200px;
  width: 100%;
  /* border-bottom: 1px solid #E0E0E0; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  /* padding-left: 40px; */
}

.header-content {
  max-width: calc(100% - 200px);
  margin-left: 80px;
  /* padding: 0 40px; */
}

.step-info {
  display: flex;
  align-items: center;
  width:100%;
  gap: 12px;
  margin-bottom: 20px;
}

.step-text {
  color: #666;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 1px;
  width: 100px;
}

.step-highlight {
  color: #b34c7a;
  width: 250px;
}

.step-dots {
  display: flex;
  align-items: center;
  gap: 120px;
  margin-left: 0px;
  position: relative;
  width: fit-content;  /* 固定幅を削除 */
}

.step-dots::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 6px;  /* 最初のドットの中心 */
  width: calc(100% - 12px);  /* 両端のドットの中心間の距離 */
  height: 1px;
  background-color: #A0A0A0;
  z-index: 0;
}

.step-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #A0A0A0;
  position: relative;
  z-index: 1;
}

.step-dot.active {
  width: 32px;
  height: 32px;
  background-color: #b34c7a;
  z-index: 2;
}

.page-title {
  font-size: 48px;
  font-weight: 300;
  color: #272b35;
  margin: 0;
  display: flex;
  align-items: baseline;
  gap: 16px;
}

.page-title-number {
  color: #b34c7a;
  font-weight: 500;
}

@media only screen and (max-width: 1200px) {
  .header-title {
    padding-left: 40px;
  }
}

@media only screen and (max-width: 900px) {
  .header-title {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 600px) {
  .header-title {
    padding-left: 20px;
  }
}

/* 最終ステップ */
.final-step-layout {
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.final-step-container {
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  box-sizing: border-box;
}

.final-step-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.final-step-top {
  flex: 1.7;
  display: flex;
  gap: 20px;
  min-height: 0;
}

.final-step-gradient {
  position: relative;
  height: 100%;
  aspect-ratio: 2/3;
  background-color: white;
  border-radius: 10px;
  padding: 10px;
}

.final-step-gradient img {
  position: absolute;
  top: 15px;
  left: 15px;
  width: calc(100% - 30px);
  height: calc(100% - 30px);
  object-fit: cover;
  /* border-radius: 20px; */
}

.final-step-webgl {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.final-step-bottom {
  flex: 1;
  display: flex;
  gap: 20px;
  min-height: 0;
}

.final-step-color-info {
  flex: 1;
  border-radius: 10px;
  padding: 20px 30px;
  color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.color-name-label {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 4px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding-bottom: 4px;
  width: fit-content;
}

.color-name-title {
  font-size: 36px;
  font-weight: 300;
  margin: 0;
  margin-bottom: 6px;
  line-height: 1.1;
  color: inherit;
}

.color-name-subtitle {
  font-size: 12px;
  opacity: 0.7;
  margin-bottom: 10px;
  font-weight: 300;
}

.color-options-list {
  list-style: none;
  padding: 0;
  margin: 0;
  flex: 1;
}

.color-option-item {
  font-size: 12px;
  /* margin-bottom: 8px; */
  opacity: 0.9;
  font-weight: 200;
}

.color-option-item span {
  margin-right: 12px;
  opacity: 0.5;
}

.rename-button {
  position: absolute;
  bottom: 30px;
  right: 30px;
  background: white;
  color: #333;
  padding: 8px 30px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 200;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 130px;
  height: 36px;
}

.rename-button i {
  font-size: 14px;
}

/* テキストの色を背景の明るさに応じて変更するためのクラス */
.light-background {
  color: #333;
}

.light-background .color-name-label {
  border-bottom-color: rgba(0, 0, 0, 0.1);
}

.light-background .color-option-item,
.light-background .color-name-subtitle {
  opacity: 0.7;
}

.rename-button.dark {
  background: #333;
  color: white;
}

.final-step-controls {
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex: 1;
  background-color: #f0f0f0;
  border-radius: 10px;
  padding: 10px;
}

.final-step-color-controls {
  flex: 4;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background-color: #ff0000; */
}

.final-step-color-controls-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 15px;
}

.color-picker-list {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  gap: 5px;
  justify-content: space-between;
}

.color-list-item {
  flex: 1;
  /* border-radius: 5px; */
  /* margin: auto; */
  width: 100%;
  cursor: pointer;
  border: 3px solid transparent;
  transition: all 0.3s ease;
}

.color-list-item:hover {
  transform: scale(1.05);
}

.color-list-item.selected {
  border-color: #000;
}

.color-picker-container {
  flex: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 10px; */
  padding: 10px;
  background-color: #ffffff;
  height: 100%;
}

.color-picker-wrapper {
  height: 70%;
  aspect-ratio: 1/1;
  /* width: 70%; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: visible !important;
}

.gradient-slider-wrapper {
  height: 15%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5px
}

input.gradient-slider {
  width: 60% !important;
  height: 2px !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  background: transparent !important;
  border-radius: 1px !important;
  outline: none !important;
  opacity: 1 !important;
}

.gradient-slider::-webkit-slider-runnable-track {
  width: 100% !important;
  height: 2px !important;
  border-radius: 1px !important;
  background: var(--track-background) !important;
}

.gradient-slider::-moz-range-track {
  width: 100% !important;
  height: 2px !important;
  border-radius: 1px !important;
  background: var(--track-background) !important;
}

.gradient-slider::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 14px !important;
  height: 14px !important;
  background: hsl(var(--current-hue, 0), var(--current-saturation, 100%), var(--current-lightness, 50%)) !important;
  border-radius: 50% !important;
  border: 1px solid #ffffff !important;
  cursor: pointer !important;
  margin-top: -7px !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
}

.gradient-slider::-moz-range-thumb {
  width: 14px !important;
  height: 14px !important;
  background: hsl(var(--current-hue, 0), var(--current-saturation, 100%), var(--current-lightness, 50%)) !important;
  border-radius: 50% !important;
  border: 1px solid #ffffff !important;
  cursor: pointer !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
}

/* 重複した定義を削除 */
.gradient-slider-container {
  display: none;
}

.final-step-shape-controls {
  flex: 6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  padding: 0 20px;
}

.shape-control-item {
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  margin-top: 25px;
}

.shape-control-item span {
  position: absolute;
  top: -25px;
  left: 12px;  /* アイコンの中心に合わせる */
  transform: translateX(-50%);
  font-size: 14px;
  color: #666;
  white-space: nowrap;
}

.shape-control-item .icon {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.shape-control-item .icon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.shape-control-item input[type="range"] {
  flex: 1;
  margin: 0 10px;
  -webkit-appearance: none !important;
  appearance: none !important;
  background: transparent !important;
}

.shape-control-item input[type="range"]::-webkit-slider-runnable-track {
  width: 100% !important;
  height: 3px !important;
  background-color: #E0E0E0 !important;
  border-radius: 5px !important;
}

.shape-control-item input[type="range"]::-moz-range-track {
  width: 100% !important;
  height: 3px !important;
  background-color: #E0E0E0 !important;
  border-radius: 5px !important;
}

.shape-control-item input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  appearance: none !important;
  width: 15px !important;
  height: 30px !important;
  border-radius: 8px !important;
  background-color: var(--principal) !important;
  border: 3px solid var(--border) !important;
  margin-top: -13.5px !important;
  cursor: pointer !important;
}

.shape-control-item input[type="range"]::-moz-range-thumb {
  width: 15px !important;
  height: 30px !important;
  border-radius: 8px !important;
  background-color: var(--principal) !important;
  border: 3px solid var(--border) !important;
  cursor: pointer !important;
}

.shape-control-item input[type="range"]:focus {
  outline: none !important;
}

.shape-control-item input[type="range"]:active::-webkit-slider-thumb {
  background-color: var(--principal) !important;
  border-color: var(--border) !important;
}

.shape-control-item input[type="range"]:active::-moz-range-thumb {
  background-color: var(--principal) !important;
  border-color: var(--border) !important;
}

.final-step-buttons {
  display: none;
  justify-content: flex-end;
  gap: 20px;
  padding-top: 10px;
}

.final-step-button {
  padding: 20px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  width: 200px;
}

.final-step-button.save {
  background-color: #ffffff;
  color: #000000;
}

.final-step-button.buy {
  background-color: #000000;
  color: #ffffff;
}

.rcp {
  display: block;
  width: 100% !important;
  height: 100% !important;
  position: absolute !important;
  top: 0;
  left: 0;
  aspect-ratio: 1/1;
  transform: scale(1.0) !important;
  transition: transform 0.15s cubic-bezier(0.68, 0, 0.47, 2);
  overflow: visible !important;
}

.rcp__knob {
  width: 14px !important;
  height: 14px !important;
  border-radius: 50% !important;
  background-color: hsl(var(--current-hue, 0), 100%, 50%) !important;
  border: 1px solid #ffffff !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) !important;
  top: 4% !important;
  left: 44% !important;
}

.rcp__palette {
  mask-image: radial-gradient(circle at 50% 50%, transparent 57%, black 57%, black 59%, transparent 59%) !important;
  border-radius: 50% !important;
}

.rcp__well {
  display: none !important;
}

.rcp.dragging {
  transform: scale(1.0) !important;
}
.unity-canvas-container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 10px;
}

#unity-canvas {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  top: 0;
  left: 0;
  background: #231F20;
}

.subtitle-text-container {
  display: flex;
  /* flex-direction: column; */
  /* align-items: center; */
  gap: 8px;
}

.page-subtitle {
  display: flex;
  /* flex-direction: column; */
  font-size: 16px;
  color: #666;
  margin-top: 8px;
  font-weight: 400;
}

.restart-button-container {
  display: flex;
  position: absolute;
  margin-top: -5px;

  right: 100px;
  /* flex-direction: column; */
  /* justify-content: flex-end; */
  /* align-items: center; */
  /* gap: 8px; */
}

.restart-button {
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: black;
  width: 150px;
  border-radius: 4px;
  font-size: 18px;
  cursor: pointer;
  transition: all 0.15s;
  letter-spacing: 1px;
  font-weight: 200;
  display: flex;
  align-items: center;
  gap: 8px;
  outline: none;
}

.restart-button:focus {
  outline: none;
}

.restart-button:active {
  transform: scale(0.98);
  background-color: rgba(0, 0, 0, 0.05);
}

.restart-button:focus:not(:active) {
  outline: none;
}

.fas {
  color: black;
}

/* ==============================================
   3. TopPage Styles
   ============================================== */
/* メインレイアウト */
.top-page {
  min-height: 100vh;
  background-color: #A3ABA4;
}

.top-content {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - 50px);
  padding: 40px 20px;
}

.top-main {
  text-align: center;
  max-width: 800px;
}

.top-heading {
  font-size: 4rem;
  font-weight: 300;
  color: #272b35;
  margin-bottom: 2rem;
  line-height: 1.2;
}

.top-description {
  font-size: 1.5rem;
  color: #666;
  margin-bottom: 3rem;
  line-height: 1.6;
}

.start-button {
  display: inline-block;
  padding: 1rem 3rem;
  font-size: 1.5rem;
  color: white;
  background-color: #b34c7a;
  border-radius: 4px;
  text-decoration: none;
  transition: background-color 0.3s;
}

.start-button:hover {
  background-color: #962557;
}

.loading-spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.loading-spinner-container i {
  font-size: 36px;
  color: white;
  opacity: 0.8;
}

.loading-text {
  font-size: 16px;
  color: white;
  opacity: 0.8;
  font-weight: 300;
  letter-spacing: 1px;
}

/* トップページの新しいスタイル */
.page {
  display: block;
  /* min-height: calc(100vh - 50px); */
  transition: opacity 0.5s ease;
}

.first-page {
  display: flex;
  padding: 80px 80px;
  align-items: center;
  height: 100vh;
  gap: 60px;
}

.content-left {
  flex: 4;
  /* min-width: 0; */
  /* display: flex; */
  /* flex-direction: column; */
  /* height: 100%; */
  height: 100%;
  width: 100%;
  position: relative;
  
}

.content-left-bottom {
  position: absolute;
  width: 100%;
  bottom: 10%;
  /* bottom: 0;
  left: 0;
  right: 0; */
  /* background-color: red; */
}


.content-right {
  flex: 6;
  height: 100%;
  width: 100%;
  /* margin: 50px 0; */
  /* min-width: 0; */
  /* display: flex; */
  /* justify-content: center;
  align-items: center; */
}

.main-heading {
  font-size: 5rem;
  font-weight: 300;
  color: #272b35;
  margin-bottom: 2rem;
  line-height: 1.2;
}

.main-description {
  font-size: 1.8rem;
  color: #666;
  margin-bottom: 3rem;
  line-height: 1.6;
}

.next-button {
  padding: 1.2rem 3.5rem;
  font-size: 1.2rem;
  color: #A3ABA4;
  background-color: white;
  border: none;
  border-radius: 50px;
  width: 55%;
  cursor: pointer;
  transition: background-color 0.3s;
}

.next-button:hover {
  background-color: #962557;
}

.hero-image {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
}

.second-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  /* height: calc(100vh - 50px); */
  background-color: #E0E0E0;
}

.second-page-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 500px;
}

.generate-button {
  padding: 0.7rem 4rem;
  font-size: 1.4rem;
  color: black;
  font-weight: 200;
  background-color: white;
  border-radius: 4px;
  text-decoration: none;
  transition: all 0.3s ease;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.generate-button:hover {
  background-color: #962557;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
}

/* レスポンシブデザイン */
/* @media only screen and (max-width: 1024px) {
  .first-page {
    padding: 40px 40px;
  }

  .main-heading {
    font-size: 4rem;
  }

  .main-description {
    font-size: 1.5rem;
  }
}

@media only screen and (max-width: 768px) {
  .main-heading {
    font-size: 3rem;
  }

  .generate-button {
    padding: 1.5rem 3rem;
    font-size: 1.5rem;
  }
} */

/* Unity Loading Bar Styles */
.unity-loading-bar {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 200px;
  z-index: 10;
}

.unity-progress-bar-empty {
  width: 100%;
  height: 4px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 2px;
  overflow: hidden;
}

.unity-progress-bar-full {
  width: 0%;
  height: 100%;
  background-color: white;
  transition: width 0.1s ease;
}

.unity-loading-text {
  color: white;
  font-size: 14px;
  font-weight: 300;
  letter-spacing: 1px;
}