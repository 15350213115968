*{
  box-sizing: border-box;
  -webkit-tap-highlight-color:rgba(0, 0, 0, 0.05);

}
html,body,#root{
  height: 100%;
}
body{
  margin: 0;
}